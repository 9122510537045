import { formatMoney } from './fmt';
import { parseMoney, parseNumber, parsePhone, parseReg } from './parser';

export function formHasError(form) {
  const keys = Object.keys(form);
  for (const key of keys) {
    if (key.endsWith('_error') && form[key]) {
      return true;
    }
  }
  return false;
}

export function formResetErrors(form) {
  const newForm = {};
  const keys = Object.keys(form);
  for (const key of keys) {
    if (!key.endsWith('_error')) {
      newForm[key] = form[key];
    }
  }
  return newForm;
}

export function formTrimAll(form) {
  const newForm = { ...form };
  const keys = Object.keys(form);
  for (const key of keys) {
    const value = form[key];
    if (value && value.trim) {
      newForm[key] = value.trim();
    }
  }
  return newForm;
}

export function formSetError(form, name) {
  const newForm = { ...form };
  newForm[`${name}_error`] = true;
  return newForm;
}

export function formNormalizeNumber(form, name) {
  const value = form[name];
  const parsed = parseNumber(value);
  if (parsed === undefined) {
    return form;
  }
  return { ...form, [name]: String(parsed) };
}

export function formNormalizeMoney(form, name) {
  const value = form[name];
  const parsed = parseMoney(value);
  if (parsed === undefined) {
    return form;
  }
  return { ...form, [name]: formatMoney(parsed, true) };
}

export function formNormalizePhone(form, name) {
  const value = form[name];
  const parsed = parsePhone(value);
  if (parsed === undefined) {
    return form;
  }
  return { ...form, [name]: parsed };
}

export function formNormalizeReg(form, name) {
  const value = form[name];
  const parsed = parseReg(value);
  if (parsed === undefined) {
    return form;
  }
  return { ...form, [name]: parsed };
}

export function formValidateRequired(form, name) {
  if (form[name]) {
    return form;
  }
  const newForm = { ...form };
  newForm[`${name}_error`] = true;
  return newForm;
}

export function formValidateRegexp(form, name, regexp) {
  const value = form[name];
  if (!value) {
    return form;
  }
  if (regexp.test(value)) {
    return form;
  }
  const newForm = { ...form };
  newForm[`${name}_error`] = true;
  return newForm;
}

export function formValidateMoney(form, name) {
  return formValidateRegexp(form, name, /^[0-9  ]+\.[0-9]{2}$/); // non-breaking space
}

export function formValidatePassword(form, name) {
  return formValidateRegexp(form, name, /^(?=.*[^\s0-9A-Za-z])(?=.*[A-Z]).{8,}$/);
}

export function formValidateEmail(form, name) {
  return formValidateRegexp(form, name, /^\S+@\S+\.\S+$/);
}

export function formValidatePhone(form, name) {
  return formValidateRegexp(form, name, /^\+7\d{10}$/);
}

export function formValidateReg(form, name) {
  return formValidateRegexp(form, name, /^[0-9A-Z]{7,9}$/);
}

export function formValidateHexColor(form, name) {
  return formValidateRegexp(form, name, /^[0-9a-f]{6}$/i);
}

export function formValidateTelegram(form, name) {
  return formValidateRegexp(form, name, /^[a-z0-9_]{5,}$/);
}
