import * as dateFns from 'date-fns';

const CONTRACTOR_TYPE = {
  individual: 'Физ. лицо',
  company: 'Юр. лицо',
};

const DRIVE = {
  rwd: 'Задний',
  fwd: 'Передний',
  '4wd': 'Полный',
};

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const ORDER_STATUS = {
  new: 'Черновик',
  progress: 'В работе',
  ready: 'Готов',
  closed: 'Закрыт',
  debt: 'Должник',
  cancelled: 'Отменён',
};

const PAYMENT_TYPE = {
  cash: 'Наличный расчёт',
  cashless: 'Безналичный расчёт',
  card: 'Карта',
};

const ROLE = {
  boss: 'Босс',
  master: 'МП',
  logistician: 'Логист',
  executor: 'Исполнитель',
};

const TRANSMISSION_TYPE = {
  manual: 'Механическая',
  automatic: 'Автоматическая',
};

export function formatContractorRole(isClient, isProvider) {
  if (isClient && isProvider) {
    return 'Клиент / Поставщик';
  }
  if (isClient) {
    return 'Клиент';
  }
  if (isProvider) {
    return 'Поставщик';
  }
  return '';
}

export function formatPersonName(name) {
  const names = name.split(' ');
  let result = names[0];
  if (names[1]) {
    result += ` ${names[1].substring(0, 1)}.`;
  }
  if (names[2]) {
    result += `${names[2].substring(0, 1)}.`;
  }
  return result;
}

export function formatContractorType(type) {
  return CONTRACTOR_TYPE[type] || '';
}

export function formatDate(value) {
  return value ? dateFns.format(value, 'DD.MM.YYYY') : '';
}

export function formatTime(value) {
  return value ? dateFns.format(value, 'HH:mm') : '';
}

export function formatDateTime(value) {
  return value ? dateFns.format(value, 'DD.MM.YYYY в HH:mm') : '';
}

export function formatDrive(drive) {
  return DRIVE[drive] || '';
}

export function formatInt(value, withSeparators = false) {
  if (value === 0) {
    return '0';
  }
  if (!value) {
    return '';
  }
  const isNegative = value < 0;
  const src = String(Math.abs(value));
  if (!withSeparators) {
    return src;
  }
  let dst = '';
  const n = src.length;
  for (let i = 0; i < n; i += 1) {
    if (i % 3 === 0 && i !== 0) {
      dst = ' ' + dst; // non-breaking space
    }
    dst = src.charAt(n - i - 1) + dst;
  }
  return isNegative ? `-${dst}` : dst;
}

export function formatMonth(value) {
  return MONTHS[value];
}

export function formatPaymentType(type) {
  return PAYMENT_TYPE[type] || '';
}

export function formatOrderStatus(status) {
  return ORDER_STATUS[status] || '';
}

export function formatPhone(phone) {
  if (!phone) {
    return '';
  }
  const p1 = phone.substr(0, 2); // +7
  const p2 = phone.substr(2, 3); // 926
  const p3 = phone.substr(5, 3); // 123
  const p4 = phone.substr(8, 2); // 45
  const p5 = phone.substr(10); // 67
  return `${p1} (${p2}) ${p3}-${p4}-${p5}`;
}

export function formatMoney(value, withKop = false, withSign = false) {
  if (!value && value !== 0) {
    return '';
  }
  const rub = Math.trunc(value / 100);
  const kop = Math.abs(value - rub * 100);
  let result = formatInt(rub, true);
  if (withKop) {
    result += '.' + (kop < 10 ? '0' : '') + kop;
  }
  if (withSign) {
    result += ' ₽'; // non-breaking space
  }
  return result;
}

export function formatRecordDuration(value) {
  if (!value) {
    return '';
  }
  return value / 60 < 1 ? `${value} мин.` : `${value / 60} ч.`;
}

export function formatRecordStatusStyle(app, value) {
  const { recordStyles } = app.getConfig().scheduler;
  const item = recordStyles.find((x) => x.value === value);
  return item ? item.title : '';
}

export function formatRole(role) {
  return ROLE[role] || '';
}

export function formatTransmissionType(type) {
  return TRANSMISSION_TYPE[type] || '';
}

export function formatWh(wh) {
  if (!wh && wh !== 0) {
    return '';
  }
  return (wh / 100).toFixed(2);
}
