import { createBrowserHistory } from 'history';

import pkg from '../../package.json';
import { createApi } from './api';
import { convertLinesToCsv } from './csv';
import { createWebSocket } from './ws';

const isProduction = true; // window.location.hostname === 'app.tigerbox.ru';

const config = {
  version: pkg.version,
  api: isProduction ? 'https://api.tigerbox.ru' : 'https://devapi.tigerbox.ru',
  wss: isProduction ? 'wss://api.tigerbox.ru' : 'wss://devapi.tigerbox.ru',
  fs: 'https://fs.cranbee.pro/tigermatic',
  desktopMinWidth: 992,
  dirRootId: 1,
  dirMaxLevel: 3,
  cacheArrayMaxLength: 100,
  scheduler: {
    timeStart: { h: 9, m: 0 },
    timeFinish: { h: 21, m: 0 },
    timeStepMinutes: 30,
    recordStyles: [
      { value: '', title: 'Синий' },
      { value: 'green', title: 'Зелёный' },
      { value: 'yellow', title: 'Жёлтый' },
      { value: 'red', title: 'Красный' },
      { value: 'muted', title: 'Серый' },
    ],
  },
  legalEntityDetails: {
    main: {
      name: 'ИП Пиркулиев А.М.',
      registrationNumber: 'ОГРНИП 317774600578890',
      phoneNumber: '+7(926)3392118',
    },
    motorka: {
      condition: { containsWorkerName: 'Трушин Алексей Юрьевич' },
      name: 'ИП Трушин А.Ю.',
      registrationNumber: 'ОГРНИП 320774600203797',
      phoneNumber: '+7(905)7178842',
    },
  },
};

export function createApp() {
  const state = initState();
  return {
    getConfig() {
      return config;
    },
    getHistory() {
      return state.history;
    },
    getApi() {
      return state.api;
    },
    getWebSocket() {
      return state.ws;
    },
    getImageUrl(name, maxWidth = 999, maxHeight = 999) {
      return `${config.fs}/images/${maxWidth}x${maxHeight}/${name}`;
    },
    setToken(token) {
      state.api = createApi(config, token);
      state.user = null;
      if (token) {
        setLsItem('token', token);
      } else {
        setLsItem('token', undefined);
      }
      setLsItem('user', undefined);
      setLsItem(getCacheKey('activities'), undefined);
      setLsItem(getCacheKey('activities/dirs'), undefined);
      setLsItem(getCacheKey('claims'), undefined);
      setLsItem(getCacheKey('contractors'), undefined);
      setLsItem(getCacheKey('orders'), undefined);
      setLsItem(getCacheKey('producers'), undefined);
      setLsItem(getCacheKey('products'), undefined);
      setLsItem(getCacheKey('products/dirs'), undefined);
      setLsItem(getCacheKey('stock/movements'), undefined);
      setLsItem(getCacheKey('workers'), undefined);
    },
    getUser() {
      return state.user;
    },
    hasRole(...roles) {
      if (!state.user) {
        return false;
      }
      const { role } = state.user;
      return role === 'root' || roles.includes(role);
    },
    setUser(user) {
      state.user = user;
      setLsItem('user', JSON.stringify(user));
      setLsItem('login', user.phone);
    },
    getLogin() {
      return getLsItem('login');
    },
    setupPage(backUrl, title) {
      state.backUrl = backUrl;
      state.title = title;
      state.update();
    },
    getBackUrl() {
      return state.backUrl;
    },
    getTitle() {
      return state.title;
    },
    showModal(type, props = {}) {
      return new Promise((resolve) => {
        props.app = this;
        props.close = (result) => {
          state.modals.pop();
          state.update();
          resolve(result);
        };
        state.modals.push({
          type,
          props,
          key: Date.now(),
        });
        state.update();
      });
    },
    getModals() {
      return state.modals;
    },
    printDocument(title, type, props = {}) {
      state.printerDocument = { type, props };
      state.update(() => openDocument(title, true));
    },
    showDocument(title, type, props = {}) {
      state.printerDocument = { type, props };
      state.update(() => openDocument(title, false));
    },
    downloadCsv(type, props = {}) {
      const { fileName, lines } = type.generateCsv(props);
      const csv = convertLinesToCsv(lines);
      saveToFile(fileName, csv);
    },
    getPrinterDocument() {
      return state.printerDocument;
    },
    setUpdate(handler) {
      state.update = handler;
    },
    onError(error) {
      if (error.status === 401) {
        state.history.push('/login');
      } else {
        alert(`Ошибка: ${error.message}`);
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    loadFromCache(key) {
      const value = getLsItem(getCacheKey(key));
      return value ? JSON.parse(value) : undefined;
    },
    saveToCache(key, value, noLimit = false) {
      const valueToCache = getValueToCache(value, noLimit);
      setLsItem(getCacheKey(key), JSON.stringify(valueToCache));
    },
    scrollToTop() {
      document.querySelector('.App_content').scrollTo(0, 0);
    },
    isMobile() {
      return window.innerWidth <= config.desktopMinWidth;
    },
    copyToClipboard(text) {
      const temporaryInput = document.createElement('textarea');
      temporaryInput.value = text;
      document.body.appendChild(temporaryInput);
      temporaryInput.select();
      document.execCommand('copy');
      document.body.removeChild(temporaryInput);
    },
  };
}

function initState() {
  const token = getLsItem('token');
  const userJson = getLsItem('user');
  return {
    history: createBrowserHistory(),
    api: createApi(config, token),
    ws: createWebSocket(config.wss),
    user: userJson ? JSON.parse(userJson) : null,
    backUrl: null,
    title: null,
    modals: [],
    printerDocument: null,
    update: null,
  };
}

function getValueToCache(value, noLimit) {
  if (noLimit) {
    return value;
  }
  if (Array.isArray(value) && value.length > config.cacheArrayMaxLength) {
    return value.slice(0, config.cacheArrayMaxLength);
  }
  return value;
}

function openDocument(title, forPrinting) {
  // https://stackoverflow.com/a/2255438
  const bodyClass = forPrinting ? 'for-printing' : '';
  const html = `
        <html>
            <head>
                <title>${title}</title>
                <link rel="stylesheet" href="/bootstrap.min.css">
                <link rel="stylesheet" href="/print.css">
            </head>
            <body class="${bodyClass}">
                ${document.getElementById('printer').innerHTML}
             </body>
        </html>
    `;
  const printerWindow = window.open();
  printerWindow.document.write(html);
  printerWindow.document.close(); // necessary for IE >= 10
  printerWindow.focus(); // necessary for IE >= 10
  printerWindow.onload = () => {
    if (forPrinting) {
      printerWindow.print();
      printerWindow.close();
    }
  };
}

function saveToFile(fileName, data) {
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  const blob = new Blob([data], { type: 'octet/stream' });
  const url = window.URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
  window.URL.revokeObjectURL(url);
}

function getCacheKey(key) {
  return `cache_${key}`;
}

function getLsItem(key) {
  const lsKey = getLsKey(key);
  const value = localStorage.getItem(lsKey);
  return value === null ? undefined : value;
}

function setLsItem(key, value) {
  const lsKey = getLsKey(key);
  if (value === undefined) {
    localStorage.removeItem(lsKey);
  } else {
    localStorage.setItem(lsKey, value);
  }
}

function getLsKey(key) {
  return `tigermatic_${key}`;
}
